import React from 'react';
import {
  IconMoreLine,
  IconLinkLine,
  IconDownloadLine,
  IconEditLine,
  IconTrashLine
} from '@instructure/ui-icons';
import { Table } from '@instructure/ui-table';
import { Menu } from '@instructure/ui-menu';
import { IconButton } from '@instructure/ui-buttons';
import formatMessage from 'format-message';

import {
  Resource,
  RESOURCE_ACTION_EDIT,
  RESOURCE_ACTION_DELETE,
  RESOURCE_ACTION_DOWNLOAD,
  RESOURCE_ACTION_SHARE_LINK
} from '../../../models/resource';
import { dispatch } from '../../../store';
import Link from '../../common/link';
import { IconText } from '../../common/icon-text';

export const availableActions = (resource) => {
  const actions = [];
  const resourceModel = new Resource(resource);

  if (resourceModel.actions.includes(RESOURCE_ACTION_DELETE)) {
    actions.push({
      label: formatMessage('Remove from Commons'),
      icon: <IconTrashLine />,
      action: 'resource/deletePrep',
    });
  }
  if (resourceModel.actions.includes(RESOURCE_ACTION_EDIT)) {
    actions.push({
      label: formatMessage('Edit resource'),
      icon: <IconEditLine />,
      action: 'resource/editScreen',
    });
  }
  if (resourceModel.actions.includes(RESOURCE_ACTION_DOWNLOAD)) {
    actions.push({
      label: formatMessage('Download'),
      icon: <IconDownloadLine />,
      action: 'resource/downloadPrep',
    });
  }
  if (resourceModel.actions.includes(RESOURCE_ACTION_SHARE_LINK)) {
    actions.push({
      label: formatMessage('Copy resource link'),
      icon: <IconLinkLine />,
      action: 'resource/copyLinkToClipboard',
    });
  }

  return actions;
};

export const boundAction = (action, resource, textareaRef = null) => () => dispatch({
  type: action,
  payload: {
    resource,
    textareaRef
  }
});

export const linkCopyTextArea = (resource, refBack) => {
  const resourceModel = new Resource(resource);
  return (
    <textarea
      className="lor-copy-resource-link-btn-link"
      ref={refBack}
      value={resourceModel.shareLink}
      readOnly
      aria-hidden="true"
      tabIndex="-1" />
  );
};

export const actionItemAsLink = ({
  label,
  icon,
  action,
  textAlign,
  resource,
  refBack
}) => {
  return (
    <Table.Cell key={action} textAlign={textAlign}>
      <Link
        display={Link.display.flex}
        renderIcon={icon}
        onClick={boundAction(action, resource, refBack.current)}
        interaction={Link.interaction.enabled}>
        {label}
      </Link>
      {action === 'resource/copyLinkToClipboard' && linkCopyTextArea(resource, refBack)}
    </Table.Cell>
  );
};

export const actionItemAsMenuItem = (label, icon, action) => {
  return (
    <Menu.Item key={action} value={action}>
      <IconText icon={icon} text={label} />
    </Menu.Item>
  );
};

export const actionMenu = ({
  resource,
  refBack,
  actions,
  textAlign,
}) => {
  const menuLabel = formatMessage('Actions on resource');

  return (
    <Table.Cell textAlign={textAlign}>
      {linkCopyTextArea(resource, refBack)}
      <Menu
        label={menuLabel}
        placement="bottom"
        withArrow={false}
        trigger={
          <IconButton
            color="primary"
            renderIcon={IconMoreLine}
            screenReaderLabel={menuLabel}
            disabled={false}
            interaction="enabled"
            withBorder={false}
            withBackground={false} />
        }
        onSelect={(_e, action) => {
          boundAction(action, resource, refBack.current)();
        }}>
        {actions.map(
          ({ label, icon, action }) => actionItemAsMenuItem(label, icon, action)
        )}
      </Menu>
    </Table.Cell>
  );
};
